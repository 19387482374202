<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein/benutzerverwaltung" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-account-edit</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          VEREINSBENUTZER BEARBEITEN
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center">
            <v-col cols="12" md="5">
              <v-text-field
                filled
                :loading="!email"
                label="E-Mail-Adresse"
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-btn
                :color="emailsuccess ? 'success' : template.colors.primary"
                :loading="changing"
                :disabled="!email"
                large
                @click="email_aendern()"
              >
                <v-scroll-x-transition hide-on-leave>
                  <div v-if="emailsuccess">
                    <v-icon color="white" class="mr-2">mdi-check-bold</v-icon>
                    E-MAIL GEÄNDERT
                  </div>
                </v-scroll-x-transition>
                <v-scroll-x-reverse-transition hide-on-leave>
                  <span v-if="!emailsuccess">E-Mail Adresse ändern</span>
                </v-scroll-x-reverse-transition>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" lg="5">
              <v-btn
                :color="resetsuccess ? 'success' : template.colors.primary"
                large
                block
                :loading="resetting"
                @click="resetPassword()"
              >
                <v-scroll-x-transition hide-on-leave>
                  <div v-if="resetsuccess">
                    <v-icon color="white" class="mr-2">mdi-check-bold</v-icon>
                    EMAIL MIT LINK VERSENDET
                  </div>
                </v-scroll-x-transition>
                <v-scroll-x-reverse-transition hide-on-leave>
                  <span v-if="!resetsuccess">PASSWORT ZURÜCKSETZEN</span>
                </v-scroll-x-reverse-transition>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center" class="mt-4">
        <v-col cols="12">
          <v-card
            :dark="!template.light"
            :light="template.light"
            class="rounded-xl pa-4"
          >
            <v-row justify="center">
              <v-col cols="12" md="10" class="py-0">
                <h4>WEITERE DATEN:</h4>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  outlined
                  v-model="new_user.rolle"
                  :items="rollen"
                  :rules="[rules.required]"
                  label="Rolle"
                ></v-select>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  v-if="new_user.rolle == 'Trainer'"
                  outlined
                  v-model="new_user.team"
                  :items="teams"
                  item-text="name"
                  item-value="name"
                  :rules="[rules.required]"
                  chips
                  multiple
                  label="Team"
                ></v-select>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  outlined
                  v-model="new_user.vorname"
                  :rules="[rules.required, rules.name]"
                  label="Vorname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  outlined
                  v-model="new_user.nachname"
                  :rules="[rules.required, rules.name]"
                  label="Nachname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="10" class="py-0">
                <v-btn
                  :color="success ? 'success' : template.colors.primary"
                  block
                  large
                  :loading="creating"
                  @click="save()"
                >
                  <v-scroll-x-transition hide-on-leave>
                    <div v-if="success">
                      <v-icon color="white" class="mr-2">mdi-check-bold</v-icon>
                      GESPEICHERT
                    </div>
                  </v-scroll-x-transition>
                  <v-scroll-x-reverse-transition hide-on-leave>
                    <span v-if="!success">SPEICHERN</span>
                  </v-scroll-x-reverse-transition>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="center" class="mt-4">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center">
            <v-col cols="12" md="6" lg="5">
              <v-btn
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                class="my-3"
                large
                block
                :loading="deleting"
                @click="deleteconfirm ? deleteUser() : (deleteconfirm = true)"
              >
                <div v-if="!deleteconfirm">
                  <v-icon class="mr-2">mdi-account-remove</v-icon>
                  BENUTZER LÖSCHEN
                </div>
                <div v-if="deleteconfirm">
                  <v-icon class="mr-2">mdi-account-remove</v-icon>
                  LÖSCHEN BESTÄTIGEN
                </div>
              </v-btn>
              <v-btn
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                class="my-3"
                large
                block
                outlined
                v-if="deleteconfirm"
                @click="deleteconfirm = false"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-snackbar v-model="error" multi-line color="danger">
      {{ errortext }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'

export default {
  name: 'Zuschauer-erfassen',
  data() {
    return {
      new_user: {
        vorname: '',
        nachname: '',
        email: '',
        password: '',
        rolle: '',
        team: '',
        verein: {
          name: '',
          logo: '',
          id: '',
        },
      },
      email: '',
      success: false,
      teams: [],
      rollen: ['Trainer', 'Vorstand', 'Zuschauerkontrolle'],
      created: false,
      creating: false,
      deleting: false,
      deleteconfirm: false,
      changing: false,
      resetting: false,
      resetsuccess: false,
      emailsuccess: false,
      valid: false,
      error: false,
      errortext: '',
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      characters: 'a-z,A-Z,0-9',
      counter: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (this.$route.params.benutzer) {
      firebase
        .firestore()
        .collection('User')
        .doc(this.$route.params.benutzer)
        .get()
        .then((doc) => {
          this.new_user = doc.data()
          this.new_user.id = doc.id
          if (!Array.isArray(this.new_user.team)) {
            var hilf = this.new_user.team
            this.new_user.team = []
            this.new_user.team.push(hilf)
          }
          this.getEmail(doc.id)
        })
    } else {
      router.push('/verein/benutzerverwaltung')
    }

    this.teams = []
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Team')
      .orderBy('name')
      .get()
      .then((docRef) => {
        docRef.forEach((doc) => {
          this.teams.push(doc.data())
          var id = this.teams.length - 1
          this.teams[id].id = doc.id
        })
        this.teamsloaded = true
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    back() {
      router.push('/verein')
    },
    getEmail(id) {
      var functions = firebase.app().functions('europe-west3')
      var getUser = functions.httpsCallable('getUser')
      getUser({ uid: id }).then((result) => {
        if (result.data.email) {
          this.email = result.data.email
        }
      })
    },
    resetPassword() {
      this.resetting = true
      var functions = firebase.app().functions('europe-west3')
      var resetPW = functions.httpsCallable('PasswortReset')
      resetPW({
        verband: this.template.var.verband,
        email: this.email,
        domain: this.template.var.app_url,
      })
        .then((result) => {
          if (result.data.success) {
            this.resetting = false
            this.resetsuccess = true
            setTimeout(() => {
              this.resetsuccess = false
            }, 4000)
          } else {
            this.error = true
            this.errortext = result.data.error
            this.resetting = false
          }
        })
        .catch((error) => {
          this.error = true
          this.errortext = result.data.error
          this.resetting = false
        })
    },
    email_aendern() {
      this.changing = true
      var functions = firebase.app().functions('europe-west3')
      var changeUser = functions.httpsCallable('changeUser')
      changeUser({ uid: this.new_user.id, email: this.email }).then(
        (result) => {
          if (result.data.user) {
            this.changing = false
            this.emailsuccess = true
            setTimeout(() => {
              this.emailsuccess = false
            }, 4000)
          }
        },
      )
    },
    deleteUser() {
      this.deleting = true
      var functions = firebase.app().functions('europe-west3')
      var deleteUser = functions.httpsCallable('deleteVereinsnutzer')
      deleteUser({ uid: this.new_user.id }).then((result) => {
        if (result.data.success) {
          this.deleting = false
          router.push('/verein/benutzerverwaltung')
        } else if (result.data.error) {
          this.deleting = false
          this.error = true
          this.errortext = result.data.error
        }
      })
    },
    save() {
      if (this.$refs.form.validate()) {
        this.creating = true
        if (!this.new_user.team) {
          this.new_user.team = ''
        }
        firebase
          .firestore()
          .collection('User')
          .doc(this.new_user.id)
          .update({
            vorname: this.new_user.vorname,
            nachname: this.new_user.nachname,
            rolle: this.new_user.rolle,
            team: this.new_user.team,
          })
          .then(() => {
            this.creating = false
            this.success = true
            setTimeout(() => {
              this.success = false
            }, 4000)
          })
      }
    },
  },
}
</script>
